<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-toolbar flat>
          <v-icon class="mr-4">mdi-account-multiple-outline</v-icon>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="create()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row dense class="ma-2">
          <v-col class="px-2">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              @input="query"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          :headers="headers"
          :items="data"
          :key="userListKey"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="paginate"
        >
          <template v-slot:item.status="{ item }">
            <v-chip small>{{ item.status }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.stop="changeStatus(item)">
                  <v-icon color="blue-grey">
                    {{
                      item.status == 'active'
                        ? 'mdi-account-cancel'
                        : 'mdi-account-check'
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ item.status === 'active' ? 'disable' : 'activate' }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      width="500px"
      @keydown.esc="dialog = false"
    >
      <user-form
        :model="editItem"
        :key="key"
        @create="handleCreate"
        @update="handleUpdate"
        @close="reset"
      ></user-form>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseList from '../../../helpers/base-list';
import UserForm from './UserForm.vue';

export default {
  name: 'UserList',

  extends: BaseList,

  components: {
    UserForm,
  },

  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', align: 'right', value: 'action', sortable: false },
    ],
    userListKey: 1,
  }),

  created() {
    this.url = 'users';
  },

  methods: {
    searchQuery(term) {
      return `name like ${term}||email like ${term}`;
    },

    changeStatus(item) {
      const successMessage = `User ${
        item.status === 'active' ? 'disabled' : 'activated'
      }.`;
      const errorMessage = 'There was an error!';

      this.$root.$confirm
        .open(
          'Are you sure?',
          `You are about to ${
            item.status === 'active' ? 'disable' : 'activate'
          } user "${item.name}". Do you want to continue?`,
          'Continue',
          'Cancel',
          { width: '500px' }
        )
        .then(() => {
          let url = `users/${item.id}/${
            item.status === 'active' ? 'disable' : 'activate'
          }`;
          this.$axios
            .put(url)
            .then(() => {
              this.$root.$toast.open(successMessage, 'success');
              this.userListKey++;
            })
            .catch(() => {
              this.$root.$toast.open(errorMessage, 'error');
            });
        })
        .catch(() => {});
    },
  },
};
</script>
