<template>
  <v-card outlined>
    <v-form v-model="valid" ref="form" @submit.prevent="validateAndSave()">
      <v-card-title class="px-5">
        {{ isEditing ? 'Edit' : 'Add' }} User
      </v-card-title>
      <v-card-text class="pa-5 pt-2">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.name"
              dense
              outlined
              label="Name"
              :rules="[(v) => !!v || 'Name is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.email"
              dense
              outlined
              label="Email"
              :rules="[(v) => !!v || 'Email is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn depressed color="primary" type="submit" :loading="loading">
              Save
            </v-btn>
            <v-btn
              outlined
              text
              class="mx-3"
              :disabled="loading"
              @click="close()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import BaseForm from '../../../helpers/base-form';

export default {
  extends: BaseForm,

  props: {
    url: { default: 'users', required: false },
  },

  methods: {
    validateAndSave() {
      if (!this.$refs.form.validate()) return;

      const successMessage = this.model.id
        ? 'User updated.'
        : 'New user added.';

      this.save(successMessage);
    },
  },
};
</script>

<style scoped>
.v-input.v-input--dense.v-text-field.v-text-field--enclosed.v-text-field--outlined {
  height: 45px !important;
}
</style>
